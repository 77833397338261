<template>
  <div>
    <div class="agent-line-container">
      <span v-text="$t('agent_line')" /> :
      <div
        v-if="agentLines.length > 0"
        class="agent-line-list-container"
      >
        <span
          v-for="(item, index) in agentLines"
          :key="item.id"
        >
          <span
            class="agent-line-list"
            @click="loadAgent(item.id)"
            v-text="item.username"
          />
          <span
            v-if="agentLines.length - 1 !== index"
            v-text="`/`"
          />
        </span>
      </div>
    </div>
    <!-- Filter -->
    <b-card
      footer-tag="footer"
      class="mb-1 card-filter-container"
    >
      <b-row>
        <b-col
          sm="12"
          md="6"
          lg="6"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('date_from')"
              />
            </b-input-group-prepend>
            <b-form-datepicker
              v-model="filter.startDate"
              :max="filter.endDate"
            />
          </b-input-group>
          <b-input-group class="mb-1-sm mb-1-md-remove">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('time_from')"
              />
            </b-input-group-prepend>
            <b-form-timepicker
              v-model="filter.startTime"
              show-seconds
              locale="en"
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('date_to')"
              />
            </b-input-group-prepend>
            <b-form-datepicker
              v-model="filter.endDate"
              :min="filter.startDate"
            />
          </b-input-group>
          <b-input-group>
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('time_to')"
              />
            </b-input-group-prepend>
            <b-form-timepicker
              v-model="filter.endTime"
              show-seconds
              locale="en"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="card-filter-item mt-1"
        >
          <b-button
            variant="primary float-right"
            @click="loadData"
            v-text="$t('search')"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-overlay
      :show="loading"
      opacity="0.80"
      rounded="sm"
    >
      <div class="b-overlay-wrap position-relative mb-1">
        <div class="vgt-wrap">
          <div class="vgt-inner-wrap">
            <div class="vgt-responsive">
              <table
                class="vgt-table bordered vgt-table-secondary"
              >
                <thead>
                  <tr>
                    <th
                      class="vgt-left-align"
                      style="min-width: auto; width: auto;"
                    >
                      <span v-text="$t('account')" />
                    </th>
                    <th
                      class="vgt-left-align"
                      style="min-width: auto; width: auto;"
                    >
                      <span v-text="$t('game_history_win_lose')" />
                    </th>
                    <th
                      class="vgt-left-align"
                      style="min-width: auto; width: auto;"
                    >
                      <span v-text="$t('rolling')" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="">
                    <td class="vgt-left-align">
                      <span v-text="agent.username" />
                    </td>
                    <td class="vgt-left-align">
                      <span v-text="new Intl.NumberFormat('fil-PH', { minimumFractionDigits: 2 }).format(agent.win_loss.win_lose)" />
                    </td>
                    <td class="vgt-left-align">
                      <span v-text="new Intl.NumberFormat('fil-PH', { minimumFractionDigits: 2 }).format(agent.win_loss.rolling)" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <b-card
        class="mb-5"
      >
        <div class="vgt-wrap ">
          <div class="vgt-inner-wrap">
            <div class="vgt-responsive">
              <table class="vgt-table bordered ">
                <thead>
                  <tr>
                    <th
                      class="vgt-left-align"
                      style="min-width: auto; width: auto;"
                    >
                      <span v-text="$t('account')" />
                    </th>
                    <th
                      class="vgt-left-align"
                      style="min-width: auto; width: auto;"
                    >
                      <span v-text="$t('game_history_win_lose')" />
                    </th>
                    <th
                      class="vgt-left-align"
                      style="min-width: auto; width: auto;"
                    >
                      <span v-text="$t('rolling')" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="record in records"
                    :key="record.user_id"
                  >
                    <td class="vgt-left-align">
                      <a
                        v-if="record.role !== 'member'"
                        href="#"
                        class="align-middle ml-50"
                        @click="loadAgent(record.id)"
                        v-text="record.username"
                      />
                      <span
                        v-else
                        v-text="record.username"
                      />
                    </td>
                    <td class="vgt-left-align">
                      <span v-text="new Intl.NumberFormat('fil-PH', { minimumFractionDigits: 2 }).format(record.win_lose)" />
                    </td>
                    <td class="vgt-left-align">
                      <span v-text="new Intl.NumberFormat('fil-PH', { minimumFractionDigits: 2 }).format(record.rolling)" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </b-card>
      <br>
    </b-overlay>

    <!-- Operation Modal -->
    <b-modal
      ref="operation-modal"
      :title="$t('agent_operation')"
      ok-title="Close"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-row>
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <statistic-card-vertical
            show-icon="false"
            :statistic="agent.username"
            :statistic-title="$t('agent')"
          />
        </b-col>
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <statistic-card-vertical
            show-icon="false"
            :statistic="selectedChildAgent.account"
            :statistic-title="$t('child_agent')"
          />
        </b-col>
      </b-row>
      <b-row
        class="mb-1"
      >
        <b-col
          cols="12"
          md="12"
          xl="12"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width: 100%"
            @click="showGameHistories()"
          >
            <feather-icon
              icon="MonitorIcon"
              class="mr-50"
            />
            <span
              class="align-middle"
              v-text="$t('game_history')"
            />
          </b-button>
        </b-col>
      </b-row>
      <b-row
        class="mb-1"
      >
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width: 100%"
            @click="showTransfer(selectedChildAgent, 'transfer_to_child', $t('transfer_to_child'))"
          >
            <feather-icon
              icon="UsersIcon"
              class="mr-50"
            />
            <span
              class="align-middle"
              v-text="$t('transfer_to_child')"
            />
          </b-button>
        </b-col>
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width: 100%"
            @click="showTransfer(selectedChildAgent, 'transfer_from_child', $t('transfer_from_child'))"
          >
            <feather-icon
              icon="UsersIcon"
              class="mr-50"
            />
            <span
              class="align-middle"
              v-text="$t('transfer_from_child')"
            />
          </b-button>
        </b-col>
      </b-row>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="operationHandleCancel"
              v-text="$t('close')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>

    <!-- Game History -->
    <b-modal
      ref="game-history-modal"
      size="xl"
      :title="`${selectedChildAgent.account} ${$t('game_history')}`"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-row class="p-1">
        <!-- Filter -->
        <b-card
          footer-tag="footer"
          class="mb-1 card-filter-container"
          style="width: 100%"
        >
          <b-row>
            <b-col
              sm="12"
              md="6"
              lg="6"
              class="card-filter-item"
            >
              <b-input-group class="mb-1">
                <b-input-group-prepend is-text>
                  <span
                    style="min-width: 130px;"
                    v-text="$t('date_from')"
                  />
                </b-input-group-prepend>
                <b-form-datepicker
                  v-model="gameHistory.filter.startDate"
                  :max="gameHistory.filter.endDate"
                />
              </b-input-group>
              <b-input-group class="mb-1-sm">
                <b-input-group-prepend is-text>
                  <span
                    style="min-width: 130px;"
                    v-text="$t('time_from')"
                  />
                </b-input-group-prepend>
                <b-form-timepicker
                  v-model="gameHistory.filter.startTime"
                  show-seconds
                  locale="en"
                />
              </b-input-group>
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="6"
              class="card-filter-item"
            >
              <b-input-group class="mb-1">
                <b-input-group-prepend is-text>
                  <span
                    style="min-width: 130px;"
                    v-text="$t('date_to')"
                  />
                </b-input-group-prepend>
                <b-form-datepicker
                  v-model="gameHistory.filter.endDate"
                  :min="gameHistory.filter.startDate"
                />
              </b-input-group>
              <b-input-group class="mb-1-sm">
                <b-input-group-prepend is-text>
                  <span
                    style="min-width: 130px;"
                    v-text="$t('time_to')"
                  />
                </b-input-group-prepend>
                <b-form-timepicker
                  v-model="gameHistory.filter.endTime"
                  show-seconds
                  locale="en"
                />
              </b-input-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
              lg="12"
              class="card-filter-item mt-1"
            >
              <b-button
                variant="primary float-right"
                @click="loadGameHistoryData"
                v-text="$t('search')"
              />
            </b-col>
          </b-row>
        </b-card>
        <b-overlay
          :show="gameHistory.loading"
          opacity="0.80"
          rounded="sm"
          style="width: 100%"
        >
          <div class="card">
            <div class="card-body">
              <div class="vgt-wrap">
                <div class="vgt-inner-wrap">
                  <div class="vgt-responsive game-history-table">
                    <table class="vgt-table bordered">
                      <thead>
                        <tr>
                          <th
                            class="vgt-left-align sortable"
                            :class="{
                              'sorting sorting-asc' : ((gameHistory.orderBy.field !== null && gameHistory.orderBy.field === 'account' && gameHistory.orderBy.sort === 'ASC') ? true : false),
                              'sorting sorting-desc' : ((gameHistory.orderBy.field !== null && gameHistory.orderBy.field === 'account' && gameHistory.orderBy.sort === 'DESC') ? true : false),
                            }"
                            @click="sortBy('account')"
                          >
                            <span v-text="$t('account')" />
                          </th>
                          <th
                            class="vgt-left-align sortable"
                            :class="{
                              'sorting sorting-asc' : ((gameHistory.orderBy.field !== null && gameHistory.orderBy.field === 'begin_time' && gameHistory.orderBy.sort === 'ASC') ? true : false),
                              'sorting sorting-desc' : ((gameHistory.orderBy.field !== null && gameHistory.orderBy.field === 'begin_time' && gameHistory.orderBy.sort === 'DESC') ? true : false),
                            }"
                            @click="sortBy('begin_time')"
                          >
                            <span v-text="$t('begin_time')" />
                          </th>
                          <th
                            class="vgt-left-align sortable"
                            :class="{
                              'sorting sorting-asc' : ((gameHistory.orderBy.field !== null && gameHistory.orderBy.field === 'vip_room' && gameHistory.orderBy.sort === 'ASC') ? true : false),
                              'sorting sorting-desc' : ((gameHistory.orderBy.field !== null && gameHistory.orderBy.field === 'vip_room' && gameHistory.orderBy.sort === 'DESC') ? true : false),
                            }"
                            @click="sortBy('vip_room')"
                          >
                            <span v-text="$t('vip_room')" />
                          </th>
                          <th
                            class="vgt-left-align sortable"
                            :class="{
                              'sorting sorting-asc' : ((gameHistory.orderBy.field !== null && gameHistory.orderBy.field === 'table_id' && gameHistory.orderBy.sort === 'ASC') ? true : false),
                              'sorting sorting-desc' : ((gameHistory.orderBy.field !== null && gameHistory.orderBy.field === 'table_id' && gameHistory.orderBy.sort === 'DESC') ? true : false),
                            }"
                            @click="sortBy('table_id')"
                          >
                            <span v-text="$t('table_id')" />
                          </th>
                          <th
                            class="vgt-left-align sortable"
                            :class="{
                              'sorting sorting-asc' : ((gameHistory.orderBy.field !== null && gameHistory.orderBy.field === 'shoe_id' && gameHistory.orderBy.sort === 'ASC') ? true : false),
                              'sorting sorting-desc' : ((gameHistory.orderBy.field !== null && gameHistory.orderBy.field === 'shoe_id' && gameHistory.orderBy.sort === 'DESC') ? true : false),
                            }"
                            @click="sortBy('shoe_id')"
                          >
                            <span v-text="$t('shoe_id')" />
                          </th>
                          <th
                            class="vgt-left-align sortable"
                            :class="{
                              'sorting sorting-asc' : ((gameHistory.orderBy.field !== null && gameHistory.orderBy.field === 'game_id' && gameHistory.orderBy.sort === 'ASC') ? true : false),
                              'sorting sorting-desc' : ((gameHistory.orderBy.field !== null && gameHistory.orderBy.field === 'game_id' && gameHistory.orderBy.sort === 'DESC') ? true : false),
                            }"
                            @click="sortBy('game_id')"
                          >
                            <span v-text="$t('game_id')" />
                          </th>
                          <th
                            class="vgt-left-align sortable"
                            :class="{
                              'sorting sorting-asc' : ((gameHistory.orderBy.field !== null && gameHistory.orderBy.field === 'bet_type' && gameHistory.orderBy.sort === 'ASC') ? true : false),
                              'sorting sorting-desc' : ((gameHistory.orderBy.field !== null && gameHistory.orderBy.field === 'bet_type' && gameHistory.orderBy.sort === 'DESC') ? true : false),
                            }"
                            @click="sortBy('bet_type')"
                          >
                            <span v-text="$t('bet_type')" />
                          </th>
                          <th
                            class="vgt-left-align sortable"
                            :class="{
                              'sorting sorting-asc' : ((gameHistory.orderBy.field !== null && gameHistory.orderBy.field === 'amount' && gameHistory.orderBy.sort === 'ASC') ? true : false),
                              'sorting sorting-desc' : ((gameHistory.orderBy.field !== null && gameHistory.orderBy.field === 'amount' && gameHistory.orderBy.sort === 'DESC') ? true : false),
                            }"
                            @click="sortBy('amount')"
                          >
                            <span v-text="$t('amount')" />
                          </th>
                          <th
                            class="vgt-left-align"
                          >
                            <span v-text="$t('player')" />
                          </th>
                          <th
                            class="vgt-left-align"
                          >
                            <span v-text="$t('banker')" />
                          </th>
                          <th
                            class="vgt-left-align"
                          >
                            <span v-text="$t('result')" />
                          </th>
                          <th
                            class="vgt-left-align"
                          >
                            <span v-text="$t('win_lose')" />
                          </th>
                          <th
                            class="vgt-left-align sortable"
                            :class="{
                              'sorting sorting-asc' : ((gameHistory.orderBy.field !== null && gameHistory.orderBy.field === 'settle_status' && gameHistory.orderBy.sort === 'ASC') ? true : false),
                              'sorting sorting-desc' : ((gameHistory.orderBy.field !== null && gameHistory.orderBy.field === 'settle_status' && gameHistory.orderBy.sort === 'DESC') ? true : false),
                            }"
                            @click="sortBy('settle_status')"
                          >
                            <span v-text="$t('settle_status')" />
                          </th>
                          <th
                            class="vgt-left-align sortable"
                            :class="{
                              'sorting sorting-asc' : ((gameHistory.orderBy.field !== null && gameHistory.orderBy.field === 'end_time' && gameHistory.orderBy.sort === 'ASC') ? true : false),
                              'sorting sorting-desc' : ((gameHistory.orderBy.field !== null && gameHistory.orderBy.field === 'end_time' && gameHistory.orderBy.sort === 'DESC') ? true : false),
                            }"
                            @click="sortBy('end_time')"
                          >
                            <span v-text="$t('end_time')" />
                          </th>
                          <th
                            class="vgt-left-align"
                          >
                            <span v-text="$t('rolling')" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in gameHistory.data"
                          :key="item.id"
                        >
                          <!-- Account -->
                          <td class="vgt-left-align">
                            <a
                              href="#"
                              class="align-middle ml-50"
                              @click="filterByAccount(item.account)"
                              v-text="item.account"
                            />
                          </td>
                          <!-- Begin Time -->
                          <td class="vgt-left-align">
                            <span v-text="item.begin_time" />
                          </td>
                          <!-- VIP Room -->
                          <td class="vgt-left-align">
                            <span v-text="item.vip_room" />
                          </td>
                          <!-- Table ID -->
                          <td class="vgt-left-align">
                            <span v-text="item.table_id" />
                          </td>
                          <!-- Shoe ID -->
                          <td class="vgt-left-align">
                            <span class="game-table-id">{{ item.game_table_id }}</span>
                            <span class="game-shoe-id">{{ item.game_shoe_id }}</span>
                            <span class="game-round-id">{{ item.game_round_id }}</span>
                          </td>
                          <!-- Game ID -->
                          <td class="vgt-left-align">
                            <span v-text="item.game_id" />
                          </td>
                          <!-- Bet Type -->
                          <td class="vgt-left-align">
                            <span
                              :class="`game-history-${item.bet_type}-type`"
                              v-text="item.bet_type.toUpperCase()"
                            />
                          </td>
                          <!-- Amount -->
                          <td class="vgt-left-align">
                            <span
                              class="align-middle ml-50"
                              v-text="Number(item.amount).toLocaleString()"
                            />
                          </td>
                          <!-- Player -->
                          <td
                            class="vgt-left-align"
                            :class="{
                              'winning-card': item.card_result === 'player'
                            }"
                          >
                            <div
                              v-if="item.player_game_card.length > 0"
                              class="text-center card-result-container"
                              :class="(item.card_result !== 'player') ? 'lose-card' : ''"
                            >
                              <span
                                v-for="(card, index) in item.player_game_card"
                                :key="index"
                                class="card-display"
                              >
                                <img
                                  :src="card.icon"
                                >
                                <span v-text="card.display" />
                              </span>
                            </div>
                          </td>
                          <!-- Banker -->
                          <td
                            class="vgt-left-align"
                            :class="{
                              'winning-card': item.card_result === 'banker'
                            }"
                          >
                            <div
                              v-if="item.banker_game_card.length > 0"
                              class="text-center"
                            >
                              <span
                                v-for="(card, index) in item.banker_game_card"
                                :key="index"
                                class="card-display"
                              >
                                <img
                                  :src="card.icon"
                                >
                                <span v-text="card.display" />
                              </span>
                            </div>
                          </td>
                          <!-- Result -->
                          <td class="vgt-left-align">
                            <span
                              :class="`card-result-${item.card_result} result-settle-status-${item.settle_status.toLowerCase()}`"
                              v-text="item.result"
                            />
                          </td>
                          <!-- Win Lose -->
                          <td class="vgt-left-align">
                            <span
                              :class="`game-result-${item.game_result}`"
                              v-text="Number(item.win_lose).toLocaleString()"
                            />
                          </td>
                          <!-- Settle Status -->
                          <td class="vgt-left-align">
                            <span
                              :class="`settle-status-${item.settle_status.toLowerCase()}`"
                              v-text="item.settle_status"
                            />
                          </td>
                          <!-- End Time -->
                          <td class="vgt-left-align">
                            <span v-text="item.end_time" />
                          </td>
                          <!-- Rolling -->
                          <td class="vgt-left-align">
                            <span
                              v-text="Number(item.rolling).toLocaleString()"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <b-pagination
                v-model="gameHistory.filter.page"
                :per-page="gameHistory.perPage"
                hide-goto-end-buttons
                :total-rows="gameHistory.totalPage"
                align="center"
                @change="loadGameHistoryData"
              />
            </div>
          </div>
        </b-overlay>
      </b-row>
    </b-modal>

    <!-- Transfer Modal -->
    <b-modal
      ref="transfer-modal"
      size="lg"
      :title="transferFormTitle"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-form
        class="transfer-modal"
        @submit.stop.prevent="transferHandleSubmit"
      >
        <b-row>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="agent.username"
              :statistic-title="$t('agent')"
            />
          </b-col>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="Number(agent.balance).toLocaleString()"
              :statistic-title="`${$t('amount')} (${agent.currency})`"
            />
          </b-col>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="`${agent.share}%`"
              :statistic-title="$t('shares')"
            />
          </b-col>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              icon="CpuIcon"
              :statistic="`${agent.commission}%`"
              :statistic-title="$t('commission')"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="selectedChildAgent.account"
              :statistic-title="$t('child_agent')"
            />
          </b-col>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="Number(selectedChildAgent.balance).toLocaleString()"
              :statistic-title="`${$t('amount')} (${agent.currency})`"
            />
          </b-col>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="`${selectedChildAgent.share}%`"
              :statistic-title="$t('shares')"
            />
          </b-col>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              icon="CpuIcon"
              :statistic="`${selectedChildAgent.commission}%`"
              :statistic-title="$t('commission')"
            />
          </b-col>
        </b-row>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('transfer_amount')"
          label-for="transfer-input-amount"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-input
                id="transfer-input-amount"
                v-model="transferFormData.amount"
                type="text"
                autocomplete="off"
                @keyup.stop.native="changeAmount()"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            />
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('memo')"
          label-for="transfer-input-memo"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-textarea
                id="transfer-input-memo"
                v-model="transferFormData.memo"
                rows="3"
                :maxlength="maxMemoCount"
                @keyup="getMemoRemainingCount()"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
              style="text-align: left;"
            >
              <span style="line-height: 38px;"><span v-text="$t('remaining_words')" /> {{ memoRemaining }}</span>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="transferHandleCancel"
              v-text="$t('cancel')"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="transferHandleSubmit"
              v-text="$t('enter')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>
  </div>
</template>

<script>

import Ripple from 'vue-ripple-directive'

import {
  BOverlay,
  BCard,
  BPagination,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BFormDatepicker,
  BFormTimepicker,
  BButton,
  BModal,
  BFormGroup,
  BForm,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'

import 'vue-good-table/dist/vue-good-table.css'

import Game from '@/models/Game'
import StatisticCardVertical from '@/components/statistics-cards/StatisticCardVertical.vue'
import Transaction from '@/models/Transaction'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { EventBus } from '../../../eventbus'

export default {
  name: 'ReportWinLose',
  components: {
    BOverlay,
    BCard,
    BPagination,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BFormDatepicker,
    BFormTimepicker,
    BButton,
    BModal,
    BFormGroup,
    BForm,
    BFormInput,
    BFormTextarea,
    StatisticCardVertical,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      pageLength: 50,
      dir: false,
      columns: [
        {
          label: 'account',
          field: 'account',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'game_history_win_lose',
          field: 'win_lose',
          type: 'number',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'rolling',
          field: 'rolling',
          type: 'number',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
      ],
      selectedChildAgent: {
        id: null,
        account: '',
      },
      records: [],
      loading: false,
      maxMemoCount: 180,
      memoRemaining: 180,
      filter: {
        agent: typeof this.$route.query.agent !== 'undefined' ? this.$route.query.agent : '',
        account: typeof this.$route.query.account !== 'undefined' ? this.$route.query.account : '',
        vipRoom: typeof this.$route.query.vipRoom !== 'undefined' ? this.$route.query.vipRoom : '',
        startDate: typeof this.$route.query.startDate !== 'undefined' ? this.$route.query.startDate : `${(new Date().getFullYear())}-${(new Date().getMonth() + 1)}-${(new Date().getDate())}`,
        endDate: typeof this.$route.query.endDate !== 'undefined' ? this.$route.query.endDate : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() + 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() + 86400000).getDate())}`,
        startTime: typeof this.$route.query.startTime !== 'undefined' ? this.$route.query.startTime : '00:00:00',
        endTime: typeof this.$route.query.endTime !== 'undefined' ? this.$route.query.endTime : '23:59:59',
      },
      currentFilter: {
        agent: typeof this.$route.query.agent !== 'undefined' ? this.$route.query.agent : '',
        account: typeof this.$route.query.account !== 'undefined' ? this.$route.query.account : '',
        vipRoom: typeof this.$route.query.vipRoom !== 'undefined' ? this.$route.query.vipRoom : '',
        startDate: typeof this.$route.query.startDate !== 'undefined' ? this.$route.query.startDate : `${(new Date().getFullYear())}-${(new Date().getMonth() + 1)}-${(new Date().getDate())}`,
        endDate: typeof this.$route.query.endDate !== 'undefined' ? this.$route.query.endDate : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() + 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() + 86400000).getDate())}`,
        startTime: typeof this.$route.query.startTime !== 'undefined' ? this.$route.query.startTime : '00:00:00',
        endTime: typeof this.$route.query.endTime !== 'undefined' ? this.$route.query.endTime : '23:59:59',
      },
      vipRoomOptions: [
        { value: '', text: 'All' },
        { value: 'SOLAIRE', text: 'SOLAIRE' },
      ],
      agent: {
        id: '',
        username: '',
        currency: '',
        role: '',
        win_loss: {
          win_lose: 0,
          rolling: 0,
          actual_profit: 0,
        },
      },
      agentLines: [],
      transferFormTitle: '',
      transferFormData: {
        loading: false,
        transfer_from: '',
        transfer_to: '',
        amount: '',
        max_amount: 0,
        memo: '',
        transaction_type: '',
        role_from: '',
        role_to: '',
      },
      gameHistory: {
        filter: {
          startDate: `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() - 518400000).getMonth() + 1)}-${(new Date((new Date()).getTime() - 518400000).getDate())}`,
          endDate: `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() + 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() + 86400000).getDate())}`,
          startTime: '00:00:00',
          endTime: '23:59:59',
          account: '',
          page: 1,
        },
        currentFilter: {
          startDate: `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() - 518400000).getMonth() + 1)}-${(new Date((new Date()).getTime() - 518400000).getDate())}`,
          endDate: `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() + 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() + 86400000).getDate())}`,
          startTime: '00:00:00',
          endTime: '23:59:59',
          account: '',
          page: 1,
        },
        orderBy: {
          field: 'begin_time',
          sort: 'DESC',
        },
        perPage: 10,
        totalPage: 0,
        loading: false,
        data: [],
      },
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async exportData(a) {
      a.preventDefault()
      try {
        await Game.downloadWinLoseList({
          ...this.currentFilter,
          agentId: this.agent.id,
        })
      } catch (e) {
        this.$alertHelper.error()
      }
    },
    changeAmount() {
      let result = 0
      let balance = 0

      if (this.transferFormData.transaction_type === 'transfer_to_child') {
        balance = this.agent.balance
      } else {
        balance = this.selectedChildAgent.balance
      }
      if (Number(String(this.transferFormData.amount).replace(/\D/g, '')) > balance) {
        result = String(balance).replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace('.00', '')
      } else {
        result = String(this.transferFormData.amount).replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      this.transferFormData.amount = result
    },
    loadAgent(id) {
      this.filter.agent = id
      this.filter.account = ''
      this.loadData(1)
    },
    async loadData() {
      this.loading = true
      const searchParams = {}
      Object.keys(this.filter).forEach(e => {
        if (typeof this.filter[e] === 'number') {
          if (this.filter[e] > 0) {
            searchParams[e] = this.filter[e]
          }
        } else if (typeof this.filter[e] === 'string') {
          if (this.filter[e] !== null && this.filter[e].length > 0) {
            searchParams[e] = this.filter[e]
          }
        }
      })
      this.$router.replace({ query: searchParams })
      const response = await Game.reportWinLose(searchParams)
      if (response.data.status) {
        this.records = response.data.records
        this.agent = response.data.meta.agent
        this.agentLines = response.data.meta.agent_lines
        this.currentFilter.account = this.filter.account
        this.currentFilter.settleStatus = this.filter.settleStatus
        this.currentFilter.vipRoom = this.filter.vipRoom
        this.currentFilter.startDate = this.filter.startDate
        this.currentFilter.endDate = this.filter.endDate
        this.currentFilter.startTime = this.filter.startTime
        this.currentFilter.endTime = this.filter.endTime
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: this.$t(response.data.message),
          },
        }, {
          position: 'top-center',
        })
      }
      EventBus.$emit('getMyUpdatedUserData') // Todo: Need to refactor

      this.loading = false
    },
    async showOperation(data) {
      this.selectedChildAgent = data
      this.$refs['operation-modal'].show()
    },
    operationHandleCancel() {
      this.$refs['operation-modal'].hide()
    },
    showTransfer(data, transactionType, title) {
      this.resetTransferFormData()
      this.transferFormTitle = title
      this.transferFormData.transaction_type = transactionType
      if (transactionType === 'transfer_to_child') {
        this.transferFormData.max_amount = this.agent.balance
        this.transferFormData.transfer_from = this.agent.id
        this.transferFormData.transfer_to = data.id
        this.max_amount = this.agent.balance
        this.transferFormData.role_from = this.agent.role
        this.transferFormData.role_to = data.role
      } else {
        this.transferFormData.max_amount = data.balance
        this.transferFormData.transfer_from = data.id
        this.transferFormData.transfer_to = this.agent.id
        this.max_amount = data.balance
        this.transferFormData.role_from = data.role
        this.transferFormData.role_to = this.agent.role
      }
      this.selectedChildAgent = data
      this.$refs['transfer-modal'].show()
    },
    transferHandleCancel() {
      this.$refs['transfer-modal'].hide()
    },
    resetTransferFormData() {
      this.transferFormData = {
        loading: false,
        transfer_from: '',
        transfer_to: '',
        amount: '',
        memo: '',
        transaction_type: '',
      }
    },
    async transferHandleSubmit() {
      try {
        if (this.transferFormData.loading === false) {
          this.transferFormData.loading = true
          const response = await Transaction.transfer(this.transferFormData)
          this.transferFormData.loading = false
          if (response.data.status) {
            this.$swal({
              title: 'Success!',
              text: response.data.results.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            await this.loadData()
            this.$refs['transfer-modal'].hide()
            this.selectedChildAgent.balance = response.data.results.custom.transaction.balance
            this.resetTransferFormData()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.results.message,
              },
            }, {
              position: 'top-center',
            })
            if (typeof response.data.results.field_id !== 'undefined') {
              document.getElementById(response.data.results.field_id).focus()
            }
          }
        }
      } catch (e) {
        this.transferFormData.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
    showGameHistories() {
      this.$refs['game-history-modal'].show()
      this.gameHistory.filter.account = this.selectedChildAgent.account
      this.gameHistory.filter.startDate = this.currentFilter.startDate
      this.gameHistory.filter.endDate = this.currentFilter.endDate
      this.gameHistory.filter.startTime = this.currentFilter.startTime
      this.gameHistory.filter.endTime = this.currentFilter.endTime
      this.loadGameHistoryData()
    },
    async loadGameHistoryData() {
      this.gameHistory.loading = true
      const searchParams = {}
      Object.keys(this.gameHistory.filter).forEach(e => {
        if (typeof this.gameHistory.filter[e] === 'number') {
          if (this.gameHistory.filter[e] > 0) {
            searchParams[e] = this.filter[e]
          }
        }
        if (typeof this.gameHistory.filter[e] === 'object') {
          if (this.gameHistory.filter[e].length > 0) {
            let newData = ''
            Object.keys(this.gameHistory.filter[e]).forEach(d => {
              newData += `${this.gameHistory.filter[e][d].value},`
            })
            searchParams[e] = newData
          }
        } else if (typeof this.gameHistory.filter[e] === 'string') {
          if (this.gameHistory.filter[e] !== null && this.gameHistory.filter[e].length > 0) {
            searchParams[e] = this.gameHistory.filter[e]
          }
        }
      })
      searchParams.orderByField = this.gameHistory.orderBy.field
      searchParams.orderBySort = this.gameHistory.orderBy.sort

      const response = await Game.histories(searchParams)
      this.gameHistory.data = response.data.data
      this.gameHistory.totalPage = response.data.meta.pagination.total
      this.gameHistory.perPage = response.data.meta.pagination.per_page

      this.gameHistory.currentFilter.account = this.filter.account
      this.gameHistory.currentFilter.startDate = this.filter.startDate
      this.gameHistory.currentFilter.endDate = this.filter.endDate
      this.gameHistory.currentFilter.startTime = this.filter.startTime
      this.gameHistory.currentFilter.endTime = this.filter.endTime

      this.gameHistory.loading = false
    },
    async sortBy(column) {
      if (this.gameHistory.orderBy.field === null || column !== this.gameHistory.orderBy.field) {
        this.gameHistory.orderBy.field = column
        this.gameHistory.orderBy.sort = 'ASC'
      } else if (this.gameHistory.orderBy.sort === 'ASC') {
        this.gameHistory.orderBy.sort = 'DESC'
      } else {
        this.gameHistory.orderBy.sort = 'ASC'
      }

      this.gameHistory.loading = true
      const searchParams = {}
      this.gameHistory.filter.page = 1
      this.gameHistory.currentFilter.page = 1
      Object.keys(this.gameHistory.currentFilter).forEach(e => {
        if (typeof this.gameHistory.currentFilter[e] === 'number') {
          if (this.gameHistory.currentFilter[e] > 0) {
            searchParams[e] = this.currentFilter[e]
          }
        }
        if (typeof this.gameHistory.currentFilter[e] === 'object') {
          if (this.gameHistory.currentFilter[e].length > 0) {
            let newData = ''
            Object.keys(this.gameHistory.currentFilter[e]).forEach(d => {
              newData += `${this.gameHistory.currentFilter[e][d].value},`
            })
            searchParams[e] = newData
          }
        } else if (typeof this.gameHistory.currentFilter[e] === 'string') {
          if (this.gameHistory.currentFilter[e] !== null && this.gameHistory.currentFilter[e].length > 0) {
            searchParams[e] = this.gameHistory.currentFilter[e]
          }
        }
      })
      searchParams.orderByField = this.gameHistory.orderBy.field
      searchParams.orderBySort = this.gameHistory.orderBy.sort

      const response = await Game.list(searchParams)
      this.gameHistory.data = response.data.data
      this.gameHistory.totalPage = response.data.meta.pagination.total
      this.gameHistory.perPage = response.data.meta.pagination.per_page

      this.gameHistory.loading = false
    },
    filterByAccount(account) {
      this.gameHistory.filter.account = account
      this.loadGameHistoryData()
    },
    getMemoRemainingCount() {
      if (this.transferFormData.memo) {
        this.memoRemaining = this.maxMemoCount - this.transferFormData.memo.length
      } else {
        this.memoRemaining = this.maxMemoCount
      }
    },
    validateMaxCommission() {
      if (this.formData.commission > this.agent.commission) {
        this.formData.commission = this.agent.commission
      }
      if (this.formData.commission < 0 || this.formData.commission.length === 0) {
        this.formData.commission = 0
      }
    },
    validateMaxShare() {
      if (this.formData.share > this.agent.share) {
        this.formData.share = this.agent.share
      }
      if (this.formData.share < 0 || this.formData.share.length === 0) {
        this.formData.share = 0
      }
    },
  },
}
</script>

<style>
</style>
